@charset "UTF-8";

@font-face {
  font-family: "untitled-font-1";
  src:url("../fonts/webzed-font-1.eot");
  src:url("../fonts/webzed-font-1.eot?#iefix") format("embedded-opentype"),
    url("../fonts/webzed-font-1.woff") format("woff"),
    url("../fonts/webzed-font-1.ttf") format("truetype"),
    url("../fonts/webzed-font-1.svg#untitled-font-1") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "untitled-font-1" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "untitled-font-1" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-home:before {
  content: "\61";
}
.icon-email-plane:before {
  content: "\63";
}
.icon-cake:before {
  content: "\62";
}
.icon-camera:before {
  content: "\64";
}
.icon-ciurclke:before {
  content: "\65";
}
.icon-star:before {
  content: "\66";
}
.icon-heart:before {
  content: "\67";
}
.icon-tie:before {
  content: "\68";
}
.icon-wine-glass:before {
  content: "\69";
}
.icon-facebook:before {
  content: "\6a";
}
.icon-twitter:before {
  content: "\6b";
}
.icon-instagram:before {
  content: "\6c";
}
.icon-tripadvisor:before {
  content: "\6d";
}
.icon-pinterest:before {
  content: "\6e";
}
.icon-caret-down:before {
  content: "\6f";
}
.icon-caret-right:before {
  content: "\70";
}
.icon-bars:before {
  content: "\71";
}
.icon-quote:before {
  content: "\72";
}
.icon-right-open-big:before {
  content: "\73";
}
.icon-left-open-big:before {
  content: "\74";
}
