html{ font-size: 100%; height: 100%; overflow-x: hidden;margin: 0px;  padding: 0px; touch-action: manipulation; }


body{ font-size: 16px; font-family: 'Bitter', serif; width: 100%; height: 100%; margin: 0; font-weight: 400;
	-webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; word-wrap: break-word; 
	color: #333; }

h1, h2, h3, h4, h5, h6, p, a, ul, span, li, img, inpot, button{ margin: 0; padding: 0; }

h1,h2,h3,h4,h5,h6{ line-height: 1.5; font-weight: inherit; }

h1,h2,h3{ font-family: 'Playball', cursive; }

p{ line-height: 1.6; font-size: 1.05em; font-weight: 400; color: #555; }

h1{ font-size: 5em; line-height: 1; }
h2{ font-size: 3.5em; line-height: 1.1; }
h3{ font-size: 2.5em; }
h4{ font-size: 1.5em; }
h5{ font-size: 1.2em; }
h6{ font-size: .9em; letter-spacing: 1px; }

a, button{ display: inline-block; text-decoration: none; color: inherit; transition: all .3s; line-height: 1; }

a:focus, a:active, a:hover,
button:focus, button:active, button:hover,
a b.light-color:hover{ text-decoration: none; color: #E45F74; }

b{ font-weight: 500; }

img{ width: 100%; }

li{ list-style: none; display: inline-block; }

span{ display: inline-block; }

button{ outline: 0; border: 0; background: none; cursor: pointer; }

b.light-color{ color: #444; }

.icon{ font-size: 1.1em; display: inline-block; line-height: inherit; }

[class^="icon-"]:before, [class*=" icon-"]:before{ line-height: inherit; }



/* ---------------------------------
2. COMMONS FOR PAGE DESIGN
--------------------------------- */

.btn{ padding: 15px 30px; border-radius: 1px; letter-spacing: 2px; 
	border: 1px solid #E45F74; background: #E45F74; color: #fff; }

.btn:hover{ color: #E45F74; background: none; }

.btn-2{ font-size: .9em; padding: 15px 30px; border-radius: 1px; letter-spacing: 2px; 
	border: 1px solid #E45F74; }

.btn-2:hover{ background: #E45F74; color: #fff; }

.border-btn{ font-size: .9em; padding-bottom: 5px; background: none; color: inherit; border: 0; 
	position: relative; overflow: hidden; }

.border-btn:after{ content:''; position: absolute; bottom: 0; left: 0; right: 0; height: 2px; 
	transition: all .3s; background: #E45F74; }

.border-btn:before{ content:''; position: absolute; bottom: 0; left: 0; right: 0; height: 2px; 
	transform: translateX(-130%); transition: all .3s; background: #E45F74; }
	
.border-btn:hover:after{ transform: translateX(130%); }

.border-btn:hover:before{ transform: translateX(0%); }


.section{ padding: 100px 0 70px; }

.section .heading{ padding-bottom: 40px; }

.section .heading .icon{ font-size: 18px; margin-top: 20px; color: #E45F74; }

.section .heading .icon.color-white{ color: #fff; }

.section .heading .heading-bottom{ margin-top: 20px; display: inline-block; width: 160px; height: 20px; 
	line-height: 20px; position: relative; }

.section .heading .heading-bottom .icon{ position: absolute; left: 50%; top: 50%; margin: -9px 0 0 -9px; }
	
.section .heading .heading-bottom:after{ content:''; position: absolute; left: 0; top: 50%; margin-top: -.5px;
	height: 1px; width: 60px; background: #aaa; }

.section .heading .heading-bottom:before{ content:''; position: absolute; right: 0; top: 50%; margin-top: -.5px;
	height: 1px; width: 60px; background: #aaa; }
	
.section .margin-bottom{ margin-bottom: 30px; }



.center-text{ text-align: center; } 

.color-white{ color: #fff; }

.display-table{ display: table; height: 100%; width: 100%; }

.display-table-cell{ display: table-cell; vertical-align: middle; }



::-webkit-input-placeholder { font-size: .9em; letter-spacing: 1px; }

::-moz-placeholder { font-size: .9em; letter-spacing: 1px; }

:-ms-input-placeholder { font-size: .9em; letter-spacing: 1px; }

:-moz-placeholder { font-size: .9em; letter-spacing: 1px; }



/* JQUERY LIGHT BOX */

#flavorOverlay { position: fixed; top: 0; bottom: 0; left: 0; right: 0; display: none; 
	background: rgba(0, 0, 0, 0.8); }

#flavorLightbox { display: none; }

#flavorLightbox > img { max-width: 90%; max-height: 90%; position: fixed; top: 50px; left: 50%;
  transform: translateX(-50%); z-index: 9999; }

#flavorLightboxClose { font-size: 32px; cursor: pointer; position: fixed; top: 30px; right: 30px;
  z-index: 666; color: #fff; }

.thumbnails { display: flex; flex-flow: row wrap; justify-content: center; z-index: 600;
  position: relative; cursor: pointer; position: absolute; bottom: 50px; width: 90%; }

.thumbnails img { height: 50px; margin: 1px; transition: .3s; }

.thumbnails img:hover { border: 2px solid #fff; }



/* ---------------------------------
3. MENU
--------------------------------- */

header{ position: absolute; left: 0; right: 0; top: 20px; z-index: 1000; font-size: .95em; 
	border-bottom: 1px solid rgba(255,255,255, .2); color: #fff; }

	
/* MIDDLE AREA */

header .logo{ margin: 5px 0 20px; height: 40px; }

header .logo img{ height: 100%; width: auto; }


/* BOTTOM AREA */

header .main-menu{ font-weight: 500; float: right; margin: 0px -15px; }

header .main-menu > li > a{ height: 50px; line-height: 46px; padding: 0 15px; border: 2px solid transparent; 
	border-radius: 2px; background: transparent; }

header .visible.main-menu{ display: block; }


/* DROPDOWN MENU STYLING */

header .main-menu li.drop-down{ position: relative; text-align: left; }

header .main-menu li.drop-down > ul.drop-down-menu{ display: none; position: absolute; top: 50px; left: 0; 
	min-width: 200px; box-shadow: 0px 3px 10px rgba(0,0,0,.3); background: #333; }

header .main-menu li.drop-down > ul.drop-down-menu li{ display: block; border-top: 1px solid #444; }

header .main-menu li.drop-down > ul.drop-down-menu li > a{ display: block; padding: 15px 20px; }

header .main-menu li.drop-down > ul.drop-down-inner li:first-child{ border-top: 0; }

header .main-menu li i{margin-left: 10px; }


/* DROPDOWN SUBMENU */

header .main-menu li.drop-down > ul.drop-down-inner{ top: 0; left: auto; left: 100%; }


/* DROPDOWN HOVER */

header .main-menu > li > a:hover,
header .main-menu li a.mouseover{ border-color: #E45F74; }

header .main-menu li.drop-down a.mouseover + ul.drop-down-menu{ display: block; 
	animation: full-opacity-anim .2s forwards; }


@keyframes full-opacity-anim{
	0%{ opacity: 0; }
	100%{ opacity: 1; }
}

/* NAV ICON */

.menu-nav-icon{ display: none; height: 60px; width: 50px; text-align: center; line-height: 60px; cursor: pointer; 
	font-size: 1.8em; }

	
	
/* ---------------------------------
4. SLIDER
--------------------------------- */

.main-slider{ height: 100vh; position: relative; z-index: 1; 
	background-image: url(./images/slider-1-1600x900.jpg); background-size: cover; color: #fff; } 

.main-slider:after{ content:''; position: absolute; top: 0; bottom: 0; left: 0; right: 0; 
	z-index: -1; background: rgba(0,0,0,.3); }

.slider-content .date{ margin: 10px 0 0; }

.slider-content i{ line-height: 1; }

.slider-content i.small-icon{ font-size: 2.5em; }

.slider-content  .title i{ transform: translateY(10px); font-size: .8em; color: #E45F74; }


/* ---------------------------------
5. STORY AREA
--------------------------------- */

.story-area{ position: relative; z-index: 1; }

.story-area:after{ content: ''; position: absolute; top: 0; bottom: 0; left: 0; right: 0; z-index: -1;
	opacity: .8; background: url(./images/Background_Circle.png); }


/* ---------------------------------
6. REMAINING TIME
--------------------------------- */

.counter-area{ position: relative; z-index: 1; 
	background-image: url(./images/large-sindur-dan-1.jpg); background-size: 1600px 500px; color: #fff; }

.counter-area:after{ content:''; position: absolute; top: 0; bottom: 0; left: 0; right: 0; 
	z-index: -1; background: rgba(0,0,0,.3); }
	
.remaining-time .time-sec{ position: relative; display: inline-block; margin: 0 20px; margin-bottom: 30px; 
  height: 120px; width: 120px; padding-top: 25px; border-radius: 100px; border: 1px solid #F14E95;
  color: #fff;
  background: rgba(241, 78, 149, 0.8);
  
  animation: pulse 1s ease infinite; }

.remaining-time .time-sec .title{ font-size: 3em; line-height: 1; display: block; }

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}



/* ---------------------------------
7. WEDDING DETAILS AREA
--------------------------------- */
.map-responsive{
    overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0;
}
.map-responsive iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
}

.wedding-details{ overflow: hidden; position: relative; }

.wedding-details:after{ content:''; position: absolute; top: 0; bottom: 0; left: 50%; margin-left: -.5px;
	width: 1px; background: #ccc; }

.w-detail{ width: 50%; padding: 0 50px; margin-bottom: 30px; position: relative; clear: both; margin-top: -40px; }

.w-detail:first-child{ margin-top: 0; }

.w-detail:last-child{ margin-bottom: 0; }

.w-detail:after{ content:''; position: absolute; top: 0; height: 18px; width: 18px; border-radius: 20px;
	z-index: 1; border: 1px solid #aaa; background: #fff; }

.w-detail.right{ text-align: left;  float: right; }

.w-detail.right:after{ left: 0; margin-left: -9px; }
	
.w-detail.left{ text-align: right;  float: left; }

.w-detail.left:after{ right: 0; margin-right: -9px; }
	
.w-detail .title{ margin: 10px 0; }

.w-detail .icon{ font-size: 3.5em; line-height: 1; color: #E45F74; }



/* ---------------------------------
8. WEDDING CEREMONY
--------------------------------- */

.ceremony-area{ position: relative; z-index: 1; 
	background-image: url(./images/large-sindur-dan-1.jpg); background-size: cover; color: #fff; }

.ceremony-area:after{ content:''; position: absolute; top: 0; bottom: 0; left: 0; right: 0; 
	z-index: -1; background: rgba(0,0,0,.3); }
	
.ceremony .desc{ margin-bottom: 15px; color: #fff;  }


/* ---------------------------------
9. CONTACT AREA
--------------------------------- */

.contact-area{ position: relative; overflow: hidden; box-shadow: 1px 10px 40px rgba(0,0,0,.4); }

.contact-area .contact-wrapper{ width: 50%; }

.contact-area .map-area{ width: 50%; position: absolute; top: 0; bottom: 0; right: 0; }


.contact-area .phone{ margin: 10px 0; font-size: 2em; color: #E45F74; }



/* ---------------------------------
10. VIEW GALLERY
--------------------------------- */

.galery-area .gallery-btn{ margin-top: 30px; }



/* ---------------------------------
11. FOOTER
--------------------------------- */

footer{ position: relative; z-index: 1; padding-top: 10px; }

footer:after{ content: ''; position: absolute; top: 0; bottom: 0; left: 0; right: 0; z-index: -1;
	opacity: .8; background: url(./images/Background_Circle.png); }
	
footer ul > li > a{ padding: 10px 15px; }

footer ul > li > a > i:hover{ color: aqua }
footer ul > li > a > img{ vertical-align: bottom; width: 24px; height:24px; }
footer ul > li > a > img:hover{ background-color: red }


footer .logo-wrapper .icon{ color: #E45F74; }

footer .logo{ display: block; height: 50px; }

footer .logo img{ height: 100%; width: auto; }

footer .newsletter{ margin: 20px 0; }

footer .newsletter .title{ margin: 5px 0; }

footer .newsletter .email-area{ position: relative; width: 400px; height: 55px; margin: 20px auto; }

footer .email-area .email-input{ position: absolute; top: 0; bottom: 0; left: 0; width: 100%; border: 0; outline: 0; 
	border-radius: 100px; padding: 0 70px 0 25px; transition: all .2s; box-shadow: 1px 10px 30px rgba(0,0,0,.2); }

footer .email-area .email-input:focus{ box-shadow: 1px 15px 30px rgba(0,0,0,.3); }

footer .email-area .submit-btn{ position: absolute; right: 0; height: 55px; width: 55px; line-height: 55px;
	border-radius: 100px; background: #E55D73; color: #fff; }

footer .email-area .submit-btn:hover{ background: #000; color: #E55D73; }


footer .copyright{ padding: 20px 0 30px; }
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.couple-wrap {
  margin: 0 auto;
  position: relative;
}

.couple-half {
  width: 50%;
  float: left;
}
.couple-half h3 {
  color: #F14E95;
  font-size: 2em;
}
.couple-half h5 {
  color: rgb(58, 29, 41);
  font-size: 1em;
  font-family: 'Playball', cursive;
}

.heartp {
  position: absolute;
  top: 3.5em;
  left: 0;
  right: 0;
  z-index: 99;
}
.heart {
  fill: red;
  position: relative;
  width: 50px;
  animation: pulse 1s ease infinite;
}



.couple-half .groom,
.couple-half .bride {
  float: left;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  width: 150px;
  height: 150px;
}

.couple-half .groom {
  float: right;
  margin-right: 5px;
}

.couple-half .bride {
  float: left;
  margin-left: 5px;
}
.couple-half .desc-groom {
  padding-right: 180px;
  text-align: right;
}

.couple-half .desc-bride {
  padding-left: 180px;
  text-align: left;
}



.couple-half .groom img,
.couple-half .bride img {
  width: 150px;
  height: 150px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.couple-half .desc-groom {
  padding-right: 180px;
  text-align: right;
}

@media screen and (max-width: 768px) {
  .couple-wrap {
    width: 100%;
  }

  .heart {
    display: none;
  }

  .couple-half {
    width: 100%;
  }

  .groom,
  .bride {
    width: 100%;
    height: 140px;
  }

  .groom img {
    width: 120px;
    height: 120px;
    margin: 0 auto;
  }

  .bride img {
    width: 120px;
    height: 120px;
    margin: 0 auto;
  }

  .groom,
  .bride {
    margin-left: 0;
    margin-right: 0;
  }

  .desc-groom {
    padding-right: 0;
    text-align: center;
  }

  .desc-bride {
    padding-left: 0;
    text-align: center;
  }
}




/* Screens Resolution : 992px
-------------------------------------------------------------------------- */
@media only screen and (max-width: 1200px) {
	
	
}

/* Screens Resolution : 992px
-------------------------------------------------------------------------- */
@media only screen and (max-width: 992px) {
	
	/* ---------------------------------
	1. PRIMARY STYLES
	--------------------------------- */

	h1{ font-size: 4em; }
	h2{ font-size: 3em; }
	h3{ font-size: 2em; }
	
	/* ---------------------------------
	3. MENU
	--------------------------------- */
	

}


/* Screens Resolution : 767px
-------------------------------------------------------------------------- */
@media only screen and (max-width: 767px) {
	
	/* ---------------------------------
	1. PRIMARY STYLES
	--------------------------------- */

	p{ line-height: 1.4; }

	h1{ font-size: 3em; }
	h2{ font-size: 2.5em; }
	h3{ font-size: 1.8em; }
	h4{ font-size: 1.5em; }
	h5{ font-size: 1.2em; }
	
	/* ---------------------------------
	2. COMMONS FOR PAGE DESIGN
	--------------------------------- */

	.section{ padding: 50px 0 20px; }

	.section .heading{ padding-bottom: 20px; }
	
	.section .heading .heading-bottom{ margin-top: 10px; }
	
	
	/* ---------------------------------
	3. MENU
	--------------------------------- */
	
	header{ top: 0; border-bottom: 1px solid rgba(255,255,255, .1); }
	
	header .logo{ margin: 10px 0; height: 40px; }
	
	
	/* BOTTOM AREA */
	
	header .main-menu{ text-align: left; display: none; float: none; clear: both; max-height: 400px;
		overflow: auto; border-top: 1px solid #ccc; background: #333; }
	
	header .main-menu > li{ display: block; float: none; border-bottom: 1px solid #444; }
	
	header .main-menu > li:last-child{ border-bottom: 0; }
	
	header .main-menu li > a{ display: block; border: 0; line-height: 1; height: auto; padding: 15px; background: none; }
	
	
	/* DROPDOWN  */
	
	header .main-menu li.drop-down > ul.drop-down-menu{ position: static; box-shadow: none; }
	
	header .main-menu li.drop-down > ul.drop-down-inner li:first-child{ border-top: 1px solid #444; }
	
	/* DROPDOWN HOVER */
	
	header .main-menu li.drop-down > ul.drop-down-menu li a{ padding-left: 25px; }
	
	header .main-menu li.drop-down > ul.drop-down-inner li a{ padding-left: 35px; }
	

	/* NAV ICON */
	
	.menu-nav-icon{ display: inline-block; float: right; }

	
	
	/* ---------------------------------
	7. WEDDING DETAILS AREA
	--------------------------------- */

	.wedding-details{ margin-left: 15px; overflow: visible; }
	
	.wedding-details:after{ left: 0; margin-left: 0px; }

	.w-detail{ width: 100%; padding: 0 0 0 30px; margin: 0 0 30px; }

	.w-detail.left,
	.w-detail.right{ text-align: left; float: none; }

	.w-detail.right:after,
	.w-detail.left:after{ left: 0; margin-left: -9px; }
	
	
	/* ---------------------------------
	9. CONTACT AREA
	--------------------------------- */
	
	.contact-area .contact-wrapper{ width: 100%; float: none!important; }

	.contact-area .map-area{ height: 400px; width: 100%; position: static; }



	/* ---------------------------------
	10. VIEW GALLERY
	--------------------------------- */

	.galery-area .gallery-btn{ margin-top: 10px; }

	
	/* ---------------------------------
	11. FOOTER
	--------------------------------- */
	
	footer ul > li > a{ padding: 10px 7px; }
	
	footer .newsletter .email-area{ width: 90%; }
	
}

/* Screens Resolution : 479px
-------------------------------------------------------------------------- */
@media only screen and (max-width: 479px) {

	/* ---------------------------------
	1. PRIMARY STYLES
	--------------------------------- */

	body{ font-size: 14px;  }
	



}

/* Screens Resolution : 359px
-------------------------------------------------------------------------- */
@media only screen and (max-width: 359px) {
	
	
}

/* Screens Resolution : 290px
-------------------------------------------------------------------------- */
@media only screen and (max-width: 290px) {
	
	
}