
  
  .timeline {
    list-style: none;
    padding: 20px 0 20px;
    position: relative;
  }
  .timeline:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 1px;
    background-color: #d4d4d4;
    left: 50%;
    margin-left: 0px;
  }
  @media screen and (max-width: 480px) {
    .timeline:before {
      margin-left: -64px;
    }
  }
  .timeline > li {
    width: 100%;
    margin-bottom: 20px;
    position: relative;
  }
  .timeline > li:before, .timeline > li:after {
    content: " ";
    display: table;
  }
  .timeline > li:after {
    clear: both;
  }
  .timeline > li > .timeline-panel {
    width: 40%;
    float: left;
    border: 1px solid #d4d4d4;
    padding: 30px;
    position: relative;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px;
  }
  .timeline > li > .timeline-panel:before {
    position: absolute;
    top: 80px;
    right: -15px;
    display: inline-block;
    border-top: 15px solid transparent;
    border-left: 15px solid #ccc;
    border-right: 0 solid #ccc;
    border-bottom: 15px solid transparent;
    content: " ";
  }
  .timeline > li > .timeline-panel:after {
    position: absolute;
    top: 81px;
    right: -14px;
    display: inline-block;
    border-top: 14px solid transparent;
    border-left: 14px solid #fff;
    border-right: 0 solid #fff;
    border-bottom: 14px solid transparent;
    content: " ";
  }
  @media screen and (max-width: 480px) {
    .timeline > li > .timeline-panel {
      width: 75% !important;
    }
    .timeline > li > .timeline-panel:before {
      top: 30px;
    }
    .timeline > li > .timeline-panel:after {
      top: 31px;
    }
  }
  .timeline > li > .timeline-badge {
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    position: relative;
    color: #fff;
    width: 160px;
    height: 160px;
    line-height: 50px;
    font-size: 1.4em;
    text-align: center;
    position: absolute;
    top: 16px;
    left: 50%;
    margin-left: -80px;
    background-color: #999999;
    z-index: 100;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
  }
  @media screen and (max-width: 480px) {
    .timeline > li > .timeline-badge {
      width: 60px;
      height: 60px;
      margin-left: -20px !important;
    }
  }
  .timeline > li.timeline-inverted > .timeline-panel {
    float: right;
  }
  .timeline > li.timeline-inverted > .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }
  .timeline > li.timeline-inverted > .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }
  
  .timeline-title {
    margin-top: 0;
  }
  
  .timeline-body > p,
  .timeline-body > ul {
    margin-bottom: 0;
  }
  
  .timeline-body > p + p {
    margin-top: 5px;
  }
  
  @media (max-width: 992px) {
    ul.timeline:before {
      left: 90px;
    }
  
    ul.timeline > li > .timeline-panel {
      width: calc(100% - 200px);
      width: -moz-calc(100% - 200px);
      width: -webkit-calc(100% - 200px);
    }
  
    ul.timeline > li > .timeline-badge {
      left: 15px;
      margin-left: 0;
      top: 16px;
    }
  
    ul.timeline > li > .timeline-panel {
      float: right;
    }
  
    ul.timeline > li > .timeline-panel:before {
      border-left-width: 0;
      border-right-width: 15px;
      left: -15px;
      right: auto;
    }
  
    ul.timeline > li > .timeline-panel:after {
      border-left-width: 0;
      border-right-width: 14px;
      left: -14px;
      right: auto;
    }
  }
  